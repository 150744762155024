
import * as Components from "./Phoseason"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "phoseason"
}

