import React, { Component, PureComponent } from 'react';
import './index.scss'
import './theme_night.scss'
import {NavLink, Link} from 'react-router-dom'
import AboutUsPage from './About'

import mobilecheck from './mobilecheck'

const IS_MOBILE = mobilecheck()

export const customOrderAppPaths = ["/", "/menu", "/order"]

export {AboutUsPage as about}

export const imgixGlobalOptions = {
  fm: 'jpg',
  bg: '222',
}

export const pages = [
  {path: "/order", label: "Order"},
  {path: "/menu", label: "Menu"},
  // {path: "/about", label: "Catering"}
]

export class SiteNav extends PureComponent {
  render() {

    return (
    <nav className="pages-nav">
      <ul>
        {pages.map(({path, label}) => (
          <li><NavLink className="page-link" exact to={path}>{label}</NavLink></li>
        ))}
      </ul>
    </nav>
  )}
}

class CoralCafe extends Component {
  render() {
    return (
      <div className="coralcafe">
      </div>
    );
  }
}
//https://ak5.picdn.net/shutterstock/videos/1024322285/preview/stock-footage-vietnamese-beef-rice-noodles-pho-with-fresh-beef-soup-stewed-in-traditional-vietnam-style.mp4
export class Header extends Component {
  render() {
    return (
      //
      <div className="landing-page-wrapper">
        <div className="landing-page">
          <div className="text-new">
            {/* <p className="decorative">Welcome to</p> */}
            <h2>Pho Season</h2>
            <p className="catch-phrase">10-hour Oxtail Base</p>
            {/* <p>family owned</p> */}
            <Link to="/order" className="order-online-link">Order Pickup / Delivery</Link>
            <p>5% Rewards</p>
            <p>new: OPEN till 9pm daily!</p>
            <p>Mon-Sun</p>
            <p>11am-9pm</p>
            
            {/* <p>We are now open on Tuesdays!</p> */}
          </div>



        </div>

        {/* <div className="special">
          
          <p>🍣 We're excited to announce: Sushi Menu is coming soon to Pho Season!</p>
        </div> */}

        <div className="special">

          <p>🌟 we use large, soup-friendly,
                paper-based and microwavable containers for all Pho.
                Enjoy anywhere anytime!</p>
        </div>
        <div className="special">
          <p>🆕 Side of onions only served if requested.</p>
        </div>
      </div>
    );
  }
}

export class OrderingHeader extends PureComponent {
  render() {
    return (
      <div className="ordering-header-wrapper">
        <div className="ordering-header">

          <div
            className="text"
             >
          

          
            <p>NEW: we use large, soup-friendly,
              paper-based and microwavable containers for all Pho.
              Enjoy anywhere anytime!</p>

              <div className="special">
          <p>🆕 Side of onions only served if requested.</p>
        </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CoralCafe;
